import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'
import tagsView from './modules/tagsView'
import permission from './modules/permission'
import settings from './modules/settings'
import getters from './getters'
import dictionary from '@/store/modules/dictionary'
import cache from '@/store/modules/cache'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
		app,
		user,
		tagsView,
		permission,
		settings,
		dictionary,
		cache
	},
	getters,
	plugins: [
		createPersistedState({
			key: process.env.VUE_APP_ENV === 'production' ? 'ADMIN.DEXP.CC' : 'SECOND_ADMIN',
			paths: ['dictionary', 'cache']
		})
	]
})

export default store
