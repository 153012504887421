import request from '@/utils/request'

function getDictionary(params) {
	return request({
		url: '/system/dictionary/view',
		method: 'get',
		params
	})
}

function listDictionary(data) {
	return request({
		url: '/system/dictionary/list',
		method: 'post',
		data
	})
}

/**
 * 平台所有字典
 * @returns {AxiosPromise}
 */
function listAllDictionary() {
	return request({
		// url: '/system/dictionary/list/simple',
		url: '/system/dictionary/list/platform/simple',
		method: 'get'
	})
}

/**
 * 根据类别、代码查询其所有子项
 * @returns {AxiosPromise}
 * @param parentId
 * @param code
 */
function findChildrenDictionary(parentId, code = null) {
	return request({
		url: '/system/dictionary/find-chilren',
		method: 'get',
		params: { code, parentId }
	})
}

/**
 * 根据父类id查询其所有子项
 * @returns {AxiosPromise}
 * @param parentId
 */
function findChildrenDictionaryById(parentId) {
	return request({
		url: '/system/dictionary/find-chilren',
		method: 'get',
		params: { parentId }
	})
}

/**
 * 根据类别、代码查询其所有子项
 * @returns {AxiosPromise}
 * @param parentCode
 * @param tenantId
 * @param code
 */
function findChildrenDictionaryByTenantId(parentCode, tenantId, code = null) {
	return request({
		url: `/system/dictionary/find-chilren/${tenantId}`,
		method: 'get',
		params: { code, parentCode }
	})
}

function findAllChildrenDictionary(code) {
	return request({
		url: '/system/dictionary/find-all-chilren',
		method: 'get',
		params: { code }
	})
}

function deleteDictionary(params) {
	return request({
		url: '/system/dictionary/delete',
		method: 'get',
		params
	})
}

function saveOrUpdateDictionary(data) {
	return request({
		url: '/system/dictionary/save-or-update',
		method: 'post',
		data
	})
}

function branchSaveOrUpdateDictionary(data = []) {
	return request({
		url: '/system/dictionary/save-or-updates',
		method: 'post',
		data
	})
}

/**
 * 选择多个数据字典项保存
 * https://second-server.zh-ips.com:20443/doc.html#/default/%E5%AD%97%E5%85%B8/selectOrSavesUsingPOST
 * @param data
 * @returns {AxiosPromise}
 */
function selectSaveDictionaries({ dictionaryIds = [], parentId }) {
	return request({
		url: '/system/dictionary/select-saves',
		method: 'post',
		data: {
			dictionaryIds,
			parentId
		}
	})
}

/**
 * 重置删除商户配置数据字典项
 * @returns {AxiosPromise}
 * @param parentId
 */
function resetDeleteDictionaries(parentId) {
	return request({
		url: '/system/dictionary/reset-delete',
		method: 'get',
		params: {
			parentId
		}
	})
}

export {
	getDictionary,
	listDictionary,
	deleteDictionary,
	listAllDictionary,
	saveOrUpdateDictionary,
	findChildrenDictionary,
	findAllChildrenDictionary,
	branchSaveOrUpdateDictionary,
	findChildrenDictionaryByTenantId,
	selectSaveDictionaries,
	resetDeleteDictionaries,
	findChildrenDictionaryById
}
