<template>
  <div class="copyright">
    <p>
      <a target="_blank" href="https://beian.miit.gov.cn">{{ icp }}</a>
    </p>
    <p>Copyright © <a target="_blank" :href="link">{{ linkText }}</a> All Rights Reserved {{ reserved }}</p>
  </div>
</template>
<script setup>
const icp = process.env.VUE_APP_ICP
const link = process.env.VUE_APP_COPYRIGHT_LINK
const linkText = process.env.VUE_APP_COPYRIGHT_LINK_TEXT
const reserved = process.env.VUE_APP_COPYRIGHT_RESERVED
</script>
<script>
export default {
  name: 'Copyright'
}
</script>

<style scoped lang="scss">
.copyright {
  p {
    font: 12px/1.5 "microsoft yahei", Helvetica, Tahoma, Arial, "Microsoft jhengHei", sans-serif;
    color: #888;
    line-height: 24px;
    margin: 0;
  }
}
</style>
