<template>
	<div :class="{ hidden: hidden }" class="pagination-container">
		<el-pagination
			:small="small"
			:background="background"
			:current-page.sync="currentPage"
			:page-size.sync="pageSize"
			:layout="layout"
			:pager-count="pagerCount"
			:page-sizes="computedPageSizes"
			:total="total"
			v-bind="$attrs"
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
		/>
	</div>
</template>

<script>
import { scrollTo } from '@/utils/scroll-to'

export default {
	name: 'Pagination',
	props: {
		total: {
			required: true,
			type: Number
		},
		page: {
			type: Number,
			default: 1
		},
		limit: {
			type: Number,
			default: 20
		},
		pageSizes: {
			type: Array,
			default() {
				return []
			}
		},
		// 移动端页码按钮的数量端默认值5
		pagerCount: {
			type: Number,
			default: document.body.clientWidth < 992 ? 5 : 7
		},
		layout: {
			type: String,
			default: 'total, sizes, prev, pager, next, jumper'
		},
		background: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		autoScroll: {
			type: Boolean,
			default: true
		},
		hidden: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		currentPage: {
			get() {
				return this.page
			},
			set(val) {
				this.$emit('update:page', val)
			}
		},
		pageSize: {
			get() {
				return this.limit
			},
			set(val) {
				this.$emit('update:limit', val)
			}
		},
		computedPageSizes() {
			let pageSizes = [10, 50, 100, 200]
			if (pageSizes.indexOf(this.limit) < 0) {
				pageSizes = [this.limit, 10, 50, 100, 200]
			}
			return pageSizes
		}
	},
	methods: {
		handleSizeChange(val) {
			this.$emit('pagination', { pageNum: this.currentPage, pageSize: val })
			if (this.autoScroll) {
				scrollTo(0, 800)
			}
		},
		handleCurrentChange(val) {
			this.$emit('pagination', { pageNum: val, pageSize: this.pageSize })
			if (this.autoScroll) {
				scrollTo(0, 800)
			}
		}
	}
}
</script>

<style scoped>
.pagination-container {
	background: #fff;
	padding: 32px 16px;
}

.pagination-container.hidden {
	display: none;
}
</style>
