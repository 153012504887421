import { listAllTenant } from '@/api/tenant'

export default {
	namespaced: true,
	state: {
		/**
		 * @type Core.AnyNumber<VuexStore.Cache>
		 */
		lastReq: {
			tenant: 0
		},
		interval: {
			tenant: 900000
		},
		cache: {
			tenant: []
		}
	},
	actions: {
		/**
		 * @param commit
		 * @param dispatch
		 * @param {boolean?} force
		 * @return {Promise<Tenant.SimpleRow[]|{length}|*>}
		 */
		async UpdateAllTenant({ commit, dispatch }, force) {
			const now = Date.now()
			const key = 'tenant'
			const { value, interval, lastReq } = await dispatch('GetCacheByKey', key)
			//间隔查询
			if (force || !value.length || now - interval >= lastReq) {
				const { data } = await listAllTenant()
				commit('SET_CACHE', {
					key,
					value: data
				})
				commit('SET_LAST_REQ', { key, value: now })
				return data
			}

			return value
		},
		/**
		 * @param state
		 * @param {string}key
		 * @return {Promise<{lastReq: number, interval: number, value: *, key:string}>}
		 */
		async GetCacheByKey({ state }, key) {
			const value = state.cache[key]
			const interval = state.interval[key]
			const lastReq = state.lastReq[key]
			return {
				key,
				value,
				interval,
				lastReq
			}
		},
		Recovery({ commit }) {
			commit('RECOVERY')
		}
	},
	mutations: {
		SET_CACHE(state, { key, value }) {
			state.cache = {
				...state.cache,
				[`${key}`]: value
			}
		},
		SET_LAST_REQ(state, { key, value }) {
			state.lastReq = {
				...state.lastReq,
				[`${key}`]: value
			}
		},
		RECOVERY(state) {
			const lastReq = {}
			Object.keys(state.lastReq).forEach((key) => {
				lastReq[key] = 0
			})
			state.lastReq = lastReq
		}
	},
	getters: {
		tenantList: (state) => state.cache.tenant
	}
}
