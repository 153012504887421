/**
 * 通用js方法封装处理
 */
import { isEmpty } from 'element-ui/src/utils/util'

const baseURL = process.env.VUE_APP_BASE_API

// 日期格式化
export function parseTime(time, pattern) {
	if (arguments.length === 0 || !time) {
		return null
	}
	const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
	let date
	if (typeof time === 'object') {
		date = time
	} else {
		if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
			time = parseInt(time)
		} else if (typeof time === 'string') {
			time = time.replace(new RegExp(/-/gm), '/')
		}
		if (typeof time === 'number' && time.toString().length === 10) {
			time = time * 1000
		}
		date = new Date(time)
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay()
	}
	const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
		let value = formatObj[key]
		// Note: getDay() returns 0 on Sunday
		if (key === 'a') {
			return ['日', '一', '二', '三', '四', '五', '六'][value]
		}
		if (result.length > 0 && value < 10) {
			value = '0' + value
		}
		return value || 0
	})
	return time_str
}

// 表单重置
export function resetForm(refName) {
	try {
		if (this.$refs[refName]) {
			this.$refs[refName].resetFields()
		}
	} catch (e) {
		console.error(e)
	}
}

// 添加日期范围
export function addDateRange(params, dateRange, propName) {
	const search = params
	search.params = {}
	if (null != dateRange && '' != dateRange) {
		if (typeof propName === 'undefined') {
			search.params['beginTime'] = dateRange[0]
			search.params['endTime'] = dateRange[1]
		} else {
			search.params['begin' + propName] = dateRange[0]
			search.params['end' + propName] = dateRange[1]
		}
	}
	return search
}

// 回显数据字典
export function selectDictLabel(datas, value) {
	const actions = []
	Object.keys(datas).some((key) => {
		if (datas[key].dictValue == '' + value) {
			actions.push(datas[key].dictLabel)
			return true
		}
	})
	return actions.join('')
}

// 回显数据字典（字符串数组）
export function selectDictLabels(datas, value, separator) {
	const actions = []
	const currentSeparator = undefined === separator ? ',' : separator
	const temp = value.split(currentSeparator)
	Object.keys(value.split(currentSeparator)).some((val) => {
		Object.keys(datas).some((key) => {
			if (datas[key].dictValue == '' + temp[val]) {
				actions.push(datas[key].dictLabel + currentSeparator)
			}
		})
	})
	return actions.join('').substring(0, actions.join('').length - 1)
}

// 通用下载方法
export function download(fileName) {
	window.location.href = baseURL + '/common/download?fileName=' + encodeURI(fileName) + '&delete=' + true
}

// 字符串格式化(%s )
export function sprintf(str) {
	let args = arguments,
		flag = true,
		i = 1
	str = str.replace(/%s/g, function () {
		var arg = args[i++]
		if (typeof arg === 'undefined') {
			flag = false
			return ''
		}
		return arg
	})
	return flag ? str : ''
}

// 转换字符串，undefined,null等转化为""parse
export function parseStrEmpty(str) {
	if (!str || str == 'undefined' || str == 'null') {
		return ''
	}
	return str
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export function handleTree(data, id, parentId, children) {
	let config = {
		id: id || 'id',
		parentId: parentId || 'parentId',
		childrenList: children || 'children'
	}

	var childrenListMap = {}
	var nodeIds = {}
	var tree = []

	for (let d of data) {
		let parentId = d[config.parentId]
		if (childrenListMap[parentId] == null) {
			childrenListMap[parentId] = []
		}
		nodeIds[d[config.id]] = d
		childrenListMap[parentId].push(d)
	}

	for (let d of data) {
		let parentId = d[config.parentId]
		if (nodeIds[parentId] == null) {
			tree.push(d)
		}
	}

	for (let t of tree) {
		adaptToChildrenList(t)
	}

	function adaptToChildrenList(o) {
		if (childrenListMap[o[config.id]] !== null) {
			o[config.childrenList] = childrenListMap[o[config.id]]
		}
		if (o[config.childrenList]) {
			for (let c of o[config.childrenList]) {
				adaptToChildrenList(c)
			}
		}
	}

	return tree
}

/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams(params) {
	let result = ''
	for (const propName of Object.keys(params)) {
		const value = params[propName]
		var part = encodeURIComponent(propName) + '='
		if (value !== null && typeof value !== 'undefined') {
			if (typeof value === 'object') {
				for (const key of Object.keys(value)) {
					if (value[key] !== null && typeof value[key] !== 'undefined') {
						let params = propName + '[' + key + ']'
						var subPart = encodeURIComponent(params) + '='
						result += subPart + encodeURIComponent(value[key]) + '&'
					}
				}
			} else {
				result += part + encodeURIComponent(value) + '&'
			}
		}
	}
	return result
}

/**
 * 数据脱敏
 * desensitization data
 * @param value
 * @returns {*}
 */
export function DP_IdNumber(value) {
	const s = '' + value
	const regular = /(\d{8})\d*(\d{4})/
	if (isEmpty(s)) {
		return ''
	}
	return s && s.replace(regular, '$1******$2')
}

export function DP_Mobile(value) {
	const s = '' + value
	const regular = /(\d{3})\d*(\d{4})/
	if (isEmpty(s)) {
		return ''
	}
	return s && s.replace(regular, '$1****$2')
}

export function DP_PersonSName(value) {
	if (isEmpty(value)) {
		return ''
	} else {
		if (value.length == 2) {
			return value.substring(0, 1) + '*' //截取name 字符串截取第一个字符，
		} else if (value.length == 3) {
			return value.substring(0, 1) + '*' + value.substring(2, 3) //截取第一个和第三个字符
		} else if (value.length > 3) {
			return value.substring(0, 1) + '*' + '*' + value.substring(3, value.length) //截取第一个和大于第4个字符
		}
	}
}

export function isNotEmpty(val) {
	return !isEmpty(val)
}

/**
 * 长字符处理
 * @param value
 * @param length
 * @returns {string}
 */
export function parseLongStrings(value, length = 5) {
	const other = value.substring(length, length + 1)
	const suffix = other == '' || !other ? '' : '...'
	return value.substring(0, length) + suffix
}

/**
 * @param time
 * @return {Promise<void>}
 */
export function wait(time) {
	return new Promise((resolve) => {
		setTimeout(resolve, time)
	})
}
