<template>
	<div class="top-right-btn">
		<el-row>
			<slot></slot>
			<el-tooltip class="item" effect="dark" :content="showSearch ? '隐藏搜索' : '显示搜索'" placement="top">
				<el-button size="mini" circle icon="el-icon-search" @click="toggleSearch" />
			</el-tooltip>
			<el-tooltip class="item" effect="dark" content="刷新" placement="top">
				<el-button size="mini" circle icon="el-icon-refresh" @click="refresh" />
			</el-tooltip>
			<el-tooltip class="item" effect="dark" content="显隐列" placement="top" v-if="columns">
				<el-button size="mini" circle icon="el-icon-menu" @click="showColumn" />
			</el-tooltip>
		</el-row>
		<el-dialog :close-on-click-modal="false" :visible.sync="open" width="40%" :title="title" :fullscreen="$_isMobile()">
			<el-transfer
				:titles="['显示', '隐藏']"
				:props="{ key: 'prop', label: 'label', disabled: 'disabled' }"
				v-model="value"
				:data="columns"
				@change="dataChange"
			/>
		</el-dialog>
	</div>
</template>
<script>
import ResizeHandler from '@/layout/mixin/ResizeHandler'

export default {
	name: 'RightToolbar',
	mixins: [ResizeHandler],
	data() {
		return {
			// 显隐数据
			value: [],
			// 弹出层标题
			title: '显示/隐藏',
			// 是否显示弹出层
			open: false,
			screenFull: false
		}
	},
	props: {
		_key: {
			type: String,
			default() {
				return 'key'
			}
		},
		showSearch: {
			type: Boolean,
			default: true
		},
		screenFullElement: {
			required: false
		},
		columns: {
			type: Array,
			default() {
				return []
			}
		}
	},
	methods: {
		/**
		 * 搜索
		 */
		toggleSearch() {
			this.$emit('update:showSearch', !this.showSearch)
		},
		/**
		 * 刷新
		 */
		refresh() {
			this.$emit('refresh')
		},
		/**
		 * 右侧列表元素变化
		 * @param data
		 */
		dataChange(data) {
			for (const item in this.columns) {
				const key = this.columns[item][this._key]
				this.columns[item].show = !data.includes(key)
			}
			console.warn(this.columns)
		},
		/**
		 * 打开显隐列dialog
		 */
		showColumn() {
			this.open = true
		}
	},
	created() {
		// 显隐列初始默认隐藏列
		for (const item in this.columns) {
			if (this.columns[item].show === false) {
				this.value.push(parseInt(item))
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.el-transfer {
	width: 80%;
	margin: 0 auto;
}

::v-deep .el-transfer__button {
	border-radius: 50%;
	padding: 12px;
	display: block;
	margin-left: 0px;
}

::v-deep .el-transfer__button:first-child {
	margin-bottom: 10px;
}
</style>
