<template>
	<div class="navbar">
		<hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />
		<breadcrumb id="breadcrumb-container" class="breadcrumb-container" v-if="!topNav" />
		<top-nav id="topmenu-container" class="topmenu-container" v-if="topNav" />
		<div class="right-menu">
			<template v-if="device !== 'mobile'">
				<search id="header-search" class="right-menu-item" v-show="false" />
				<ScreenFull id="ScreenFull" class="right-menu-item hover-effect" />
				<el-tooltip content="布局大小" effect="dark" placement="bottom">
					<size-select id="size-select" class="right-menu-item hover-effect" />
				</el-tooltip>
			</template>
			<el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
				<div class="avatar-wrapper">
					<img :src="avatar" class="user-avatar" />
					<i class="el-icon-caret-bottom" />
				</div>
				<el-dropdown-menu slot="dropdown">
					<router-link v-if="false" to="/user/profile">
						<el-dropdown-item>个人中心</el-dropdown-item>
					</router-link>
					<el-dropdown-item @click.native="passwordDialogShow = true">
						<span>修改密码</span>
					</el-dropdown-item>
					<el-dropdown-item @click.native="setting = true">
						<span>布局设置</span>
					</el-dropdown-item>
					<el-dropdown-item divided @click.native="logout">
						<span>退出登录</span>
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
		<el-dialog :close-on-click-modal="false" title="修改密码" :visible.sync="passwordDialogShow" width="400px" @close="passwordDialogClose">
			<el-form ref="changePasswordForm" :model="passwordDialogForm" :rules="passwordDialogFormRule" label-width="auto">
				<el-form-item label="旧密码" prop="oldPwd">
					<el-input type="password" v-model="passwordDialogForm.oldPwd" autocomplete="off" show-password />
				</el-form-item>
				<el-form-item label="新密码" prop="password">
					<el-input type="password" v-model="passwordDialogForm.password" autocomplete="off" show-password />
				</el-form-item>
				<el-form-item label="确认密码" prop="repeat">
					<el-input type="password" v-model="passwordDialogForm.repeat" autocomplete="off" show-password />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button :loading="passwordDialogLoading" type="primary" @click="passwordDialogSubmit">确 定</el-button>
				<el-button @click="passwordDialogClose">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import TopNav from '@/components/TopNav'
import Hamburger from '@/components/Hamburger'
import ScreenFull from '@/components/ScreenFull'
import SizeSelect from '@/components/SizeSelect'
import Search from '@/components/HeaderSearch'
import { removeToken } from '@/utils/auth'
import Crypto from 'crypto'
import { changeSelfPwd } from '@/api/user'

export default {
	components: {
		Breadcrumb,
		TopNav,
		Hamburger,
		ScreenFull,
		SizeSelect,
		Search
	},
	data() {
		return {
			passwordDialogShow: false,
			passwordDialogLoading: false,
			passwordDialogForm: {
				oldPwd: '',
				password: '',
				repeat: ''
			},
			passwordDialogFormRule: {
				oldPwd: [{ required: true, message: '请输入原密码' }],
				password: [{ required: true, message: '请输入新密码' }],
				repeat: [
					{ required: true, message: '请确认新密码' },
					{
						validator: (rule, value, callback) => {
							if (this.passwordDialogForm.password !== value) {
								callback(new Error('确认密码与新密码不一致'))
							} else {
								callback()
							}
						},
						trigger: 'blur'
					}
				]
			}
		}
	},
	computed: {
		...mapGetters(['sidebar', 'avatar', 'device', 'user']),
		setting: {
			get() {
				return this.$store.state.settings.showSettings
			},
			set(val) {
				this.$store.dispatch('settings/changeSetting', {
					key: 'showSettings',
					value: val
				})
			}
		},
		topNav: {
			get() {
				return this.$store.state.settings.topNav
			}
		}
	},
	methods: {
		toggleSideBar() {
			this.$store.dispatch('app/toggleSideBar')
		},
		async logout() {
			this.$confirm('确定注销并退出系统吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$store.dispatch('cache/GetCacheByKey')
					return this.$store.dispatch('LogOut')
				})
				.then(() => {})
				.catch(() => {})
				.finally(() => {
					removeToken()
					this.$router.replace('login').catch(() => {})
				})
		},
		passwordDialogSubmit() {
			this.$refs.changePasswordForm.validate((valid) => {
				if (valid) {
					this.passwordDialogLoading = true
					const password = Crypto.createHash('md5').update(this.passwordDialogForm.password).digest('hex')
					const oldPwd = Crypto.createHash('md5').update(this.passwordDialogForm.oldPwd).digest('hex')
					const { loginNo } = this.user.info
					changeSelfPwd({ loginNo, oldPwd, password })
						.then((res) => {
							this.$refs.changePasswordForm.resetFields()
							this.notifySuccess(res.msg)
							this.passwordDialogClose()
						})
						.catch((err) => {
							this.notifyError(err)
						})
						.finally(() => {
							this.passwordDialogLoading = false
						})
				} else {
					return false
				}
			})
		},
		passwordDialogClose() {
			this.passwordDialogShow = false
		}
	}
}
</script>

<style lang="scss" scoped>
.navbar {
	height: 50px;
	overflow: hidden;
	position: relative;
	background: #fff;
	box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

	.hamburger-container {
		line-height: 46px;
		height: 100%;
		float: left;
		cursor: pointer;
		transition: background 0.3s;
		-webkit-tap-highlight-color: transparent;

		&:hover {
			background: rgba(0, 0, 0, 0.025);
		}
	}

	.breadcrumb-container {
		float: left;
	}

	.topmenu-container {
		position: absolute;
		left: 50px;
	}

	.errLog-container {
		display: inline-block;
		vertical-align: top;
	}

	.right-menu {
		float: right;
		height: 100%;
		line-height: 50px;

		&:focus {
			outline: none;
		}

		.right-menu-item {
			display: inline-block;
			padding: 0 8px;
			height: 100%;
			font-size: 18px;
			color: #5a5e66;
			vertical-align: text-bottom;

			&.hover-effect {
				cursor: pointer;
				transition: background 0.3s;

				&:hover {
					background: rgba(0, 0, 0, 0.025);
				}
			}
		}

		.avatar-container {
			margin-right: 30px;

			.avatar-wrapper {
				margin-top: 5px;
				position: relative;

				.user-avatar {
					cursor: pointer;
					width: 40px;
					height: 40px;
					border-radius: 10px;
				}

				.el-icon-caret-bottom {
					cursor: pointer;
					position: absolute;
					right: -20px;
					top: 25px;
					font-size: 12px;
				}
			}
		}
	}
}
</style>
