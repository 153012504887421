<template>
  <el-card class="content-wrapper">
    <canvas :id="id" :width="width" :height="height">请使用支持HTML5的浏览器</canvas>
    <div class="tool-wrapper">
      <div>
        <transition name="fade-transform" mode="out-in">
          <el-button type="danger" v-if="active" @click="handleFabricDelete">
            <div class="flex">
              <svg-icon icon-class="trash" />
              <div class="mx-2"></div>
              删除
            </div>
          </el-button>
        </transition>
      </div>
      <transition name="fade-transform" mode="out-in">
        <slot name="tool" :active="active"></slot>
      </transition>
      <el-button v-if="aka" :disabled="drawType === 'select'" @click="changeDrawModel('select')">
        <svg-icon icon-class="select_area" />
      </el-button>
      <el-button v-if="aka" :disabled="drawType === 'text'" @click="changeDrawModel('text')">
        <svg-icon icon-class="text" />
      </el-button>
      <el-button v-if="aka" @click="changeDrawMore" icon="el-icon-magic-stick"></el-button>
      <i v-if="aka" @click="rotate" class="pt-iconfont el-icon-refresh-right"></i>
      <i v-if="aka" @click="selected" class="pt-iconfont el-icon-top-right"></i>
    </div>
  </el-card>
</template>

<script setup>
import { onMounted, reactive, toRefs } from 'vue'
import { fabric } from 'fabric'

const props = defineProps({
  id: {
    type: String,
    required: false,
    default: 'fabricCanvas'
  },
  width: {
    type: Number,
    required: true
  },
  height: {
    type: Number,
    required: true
  }
})

const $emit = defineEmits([
  'deleted',
  'mouse:down',
  'mouse:up',
  'mouse:move',
  'mouse:dblclick',
  'mouse:over',
  'mouse:out',
  'selection:created',
  'selection:updated',
  'selection:cleared',
  'before:selection:cleared',

  'object:added',
  'object:removed',
  'object:modified',
  'object:rotating',
  'object:scaling',
  'object:moving',
  'object:mousedown',
  'object:mouseup',
  'object:mousemove',
  'object:mouseover',
  'object:mouseout',
  'object:mousedblclick',
  'object:mousewheel'
])

const reactiveData = reactive({
  aka: false,
  canvas: null,
  currentObj: null,
  isDrawingMode: false,
  drawType: 'select',
  doDrawing: false,
  textBox: null,
  moveCount: 1,
  mouseFrom: {},
  mouseTo: {},
  active: false
})

function onCreated() {
}

onCreated()

onMounted(() => {
  init()
})

let fabricCanvas = null

function init() {
  fabricCanvas = new fabric.Canvas(props.id, { preserveObjectStacking: true })
  let canvas = fabricCanvas
  canvas.backgroundColor = 'transparent'
  fabricCanvas.controlsAboveOverlay = false
  fabricCanvas.skipOffscreen = true
  drawControls()
  fabricCanvas.on('selection:created', function(options) {
    console.debug('selection:created')
    $emit('selection:created', options)
  })
  fabricCanvas.on('mouse:down', function(options) {
    console.debug('mouse:down')
    const xy = transformMouse(options.e.offsetX, options.e.offsetY)
    reactiveData.mouseFrom.x = xy.x
    reactiveData.mouseFrom.y = xy.y
    reactiveData.doDrawing = true
    $emit('mouse:down', options)

    reactiveData.active = Boolean(fabricCanvas.getActiveObject())
  })
  fabricCanvas.on('mouse:up', function(options) {
    console.debug('mouse:up')
    const xy = transformMouse(options.e.offsetX, options.e.offsetY)
    reactiveData.mouseTo.x = xy.x
    reactiveData.mouseTo.y = xy.y
    reactiveData.moveCount = 1
    reactiveData.doDrawing = false
    $emit('mouse:up', options)
    reactiveData.active = Boolean(fabricCanvas.getActiveObject())
  })
  fabricCanvas.on('mouse:move', function(options) {
    console.debug('mouse:move')
    if (reactiveData.moveCount % 2 && !reactiveData.doDrawing) {
      //减少绘制频率
      return
    }
    reactiveData.moveCount++
    const xy = transformMouse(options.e.offsetX, options.e.offsetY)
    reactiveData.mouseTo.x = xy.x
    reactiveData.mouseTo.y = xy.y
    if (reactiveData.drawType !== 'text') drawing()
    $emit('mouse:move', options)
  })
  fabricCanvas.on('mouse:dblclick', function(options) {
    console.debug('mouse:dblclick')
    if (reactiveData.drawType === 'text') drawing()
    $emit('mouse:dblclick', options)
  })
  fabricCanvas.on('mouse:over', function(options) {
    console.debug('mouse:over')
    $emit('mouse:over', options)
  })
  fabricCanvas.on('mouse:out', function(options) {
    console.debug('mouse:out')
    $emit('mouse:out', options)
  })
  fabricCanvas.on('object:added', function(options) {
    console.debug('mouse:added')
    $emit('object:added', options)
    reactiveData.active = Boolean(fabricCanvas.getActiveObject())
  })
  fabricCanvas.on('object:removed', function(options) {
    console.debug('object:removed')
    $emit('object:removed', options)
  })
  fabricCanvas.on('object:modified', function(options) {
    console.debug('object:modified')
    $emit('object:modified', options)
  })
  fabricCanvas.on('object:rotating', function(options) {
    console.debug('object:rotating')
    $emit('object:rotating', options)
  })
  fabricCanvas.on('object:scaling', function(options) {
    console.debug('object:scaling')
    $emit('object:scaling', options)
  })
  fabricCanvas.on('object:moving', function(options) {
    console.debug('object:moving')
    $emit('object:moving', options)
  })
  fabricCanvas.on('selection:updated', function(options) {
    console.debug('selection:updated')
    $emit('selection:updated', options)
  })
  fabricCanvas.on('selection:cleared', function(options) {
    console.debug('selection:cleared')
    $emit('selection:cleared', options)
  })
  fabricCanvas.on('before:selection:cleared', function(options) {
    console.debug('before:selection:cleared')
    $emit('before:selection:cleared', options)
  })
}

function drawing() {
  switch (reactiveData.drawType) {
    case 'text':
      reactiveData.textBox = new fabric.Textbox('', {
        left: reactiveData.mouseFrom.x - 60,
        top: reactiveData.mouseFrom.y - 20,
        width: 120,
        fontSize: 12,
        borderColor: '#48d433',
        fill: '#48d433',
        hasControls: false
      })
      fabricCanvas.add(reactiveData.textBox)
      reactiveData.textBox.enterEditing()
      reactiveData.textBox.hiddenTextarea.focus()
      fabricCanvas.add(reactiveData.textBox)
      fabricCanvas.renderAll()
      break
  }
}

function transformMouse(mouseX, mouseY) {
  const e = event || window.event
  const scrollX = document.documentElement.scrollLeft || document.body.scrollLeft
  const scrollY = document.documentElement.scrollTop || document.body.scrollTop
  return { x: e.pageX || e.clientX + scrollX, y: e.pageY || e.clientY + scrollY }
}

function drawDottedLine(options) {
  options = Object.assign(
    {
      x: 0,
      y: 0,
      x1: 10,
      y1: 10,
      color: '#B2B2B2',
      drawWidth: 2,
      offset: 6,
      empty: 3
    },
    options
  )
  let canvasObject = new fabric.Line([options.x, options.y, options.x1, options.y1], {
    ...options,
    strokeDashArray: [options.offset, options.empty],
    stroke: options.color,
    strokeWidth: options.drawWidth
  })
  fabricCanvas.add(canvasObject)
  fabricCanvas.renderAll()
}

function drawArrowLine(options) {
  options = Object.assign(
    {
      x: 0,
      y: 0,
      x1: 0,
      y1: 0,
      color: '#B2B2B2',
      drawWidth: 2,
      fillColor: 'rgba(255,255,255,0)',
      theta: 35,
      headlen: 35
    },
    options
  )
  let canvasObject = new fabric.Path(drawArrowBase(options.x, options.y, options.x1, options.y1, options.theta, options.headlen), {
    ...options,
    stroke: options.color,
    fill: options.fillColor,
    strokeWidth: options.drawWidth
  })
  fabricCanvas.add(canvasObject)
  fabricCanvas.renderAll()
}

function drawArrowBase(fromX, fromY, toX, toY, theta, headlen) {
  theta = typeof theta !== 'undefined' ? theta : 30
  headlen = typeof theta !== 'undefined' ? headlen : 10
  // 计算各角度和对应的P2,P3坐标
  const angle = (Math.atan2(fromY - toY, fromX - toX) * 180) / Math.PI,
    angle1 = ((angle + theta) * Math.PI) / 180,
    angle2 = ((angle - theta) * Math.PI) / 180,
    topX = headlen * Math.cos(angle1),
    topY = headlen * Math.sin(angle1),
    botX = headlen * Math.cos(angle2),
    botY = headlen * Math.sin(angle2)
  let arrowX = fromX - topX,
    arrowY = fromY - topY
  let path = ' M ' + fromX + ' ' + fromY
  path += ' L ' + toX + ' ' + toY
  arrowX = toX + topX
  arrowY = toY + topY
  path += ' M ' + arrowX + ' ' + arrowY
  path += ' L ' + toX + ' ' + toY
  arrowX = toX + botX
  arrowY = toY + botY
  path += ' L ' + arrowX + ' ' + arrowY
  return path
}

function freeDrawConfig(options) {
  options = Object.assign({ color: '#b2b2b2', drawWidth: 2 }, options)

  fabricCanvas.isDrawingMode = options.isDrawingMode
  fabricCanvas.freeDrawingBrush.color = options.color // 设置自由绘颜色
  fabricCanvas.freeDrawingBrush.width = options.drawWidth
  fabricCanvas.renderAll()
}

function eraseDrawConfig(options) {
  options = Object.assign({ color: 'white', drawWidth: 2 }, options)

  fabricCanvas.freeDrawingBrush.color = options.color // 设置自由绘颜色
  fabricCanvas.freeDrawingBrush.width = options.drawWidth
  fabricCanvas.renderAll()
}

function removeCurrentObj() {
  let obj = fabricCanvas.getActiveObject()
  fabricCanvas.remove(obj)
  fabricCanvas.renderAll()
}

/**
 * @return {Fabric.FabricObject}
 */
function getEditObj() {
  let obj = fabricCanvas.getActiveObject()
  removeCurrentObj()
  return obj
}

function addObj(obj) {
  fabricCanvas.add(obj)
  fabricCanvas.renderAll()
}

function setRotate(deg = 36) {
  let obj = fabricCanvas.getActiveObject()
  let angle = obj.angle
  obj.rotate(angle + deg)
  fabricCanvas.renderAll()
}

function discardActive() {
  fabricCanvas.discardActiveObject()
  // fabricCanvas.discardActiveGroup();
  fabricCanvas.renderAll()
}

function deactivateAll() {
  // fabricCanvas.deactivateAll().renderAll();
}

function deactivateOne(obj) {
  const activeGroup = fabricCanvas.getActiveGroup()
  activeGroup.removeWithUpdate(obj)
  fabricCanvas.renderAll()
}

function setSelection(flag) {
  fabricCanvas.selection = flag
  fabricCanvas.renderAll()
}

function moveTo() {
  let obj = fabricCanvas.getActiveObject()
  fabricCanvas.sendBackwards(obj, true)
  fabricCanvas.discardActiveObject()
  // fabricCanvas.discardActiveGroup();
}

function createRect(options) {
  options = Object.assign({ width: 0, height: 0, fillColor: 'rgba(255, 255, 255, 0)', left: 50, top: 50 }, options)
  let rect = new fabric.Rect({
    ...options,
    fill: options.fillColor // 填充的颜色
  })
  fabricCanvas.add(rect)
  fabricCanvas.renderAll()
}

function createCircle(options) {
  options = Object.assign(
    {
      left: 0,
      top: 0,
      radius: 30,
      fillColor: 'rgba(255, 255, 255, 0)',
      color: '#B2B2B2',
      drawWidth: 2
    },
    options
  )
  let defaultOption = {
    ...options,
    fill: options.fillColor,
    strokeWidth: options.drawWidth,
    stroke: options.color
  }
  let Circle = new fabric.Circle(defaultOption)
  fabricCanvas.add(Circle)
  fabricCanvas.renderAll()
}

function createTriangle(options) {
  options = Object.assign(
    {
      x: 0,
      y: 0,
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 0,
      left: 100,
      top: 100,
      color: '#B2B2B2',
      drawWidth: 2,
      fillColor: 'rgba(255, 255, 255, 0)',
      id: 'triangle'
    },
    options
  )
  const path = 'M ' + options.x + ' ' + options.y + ' L ' + options.x1 + ' ' + options.y1 + ' L ' + options.x2 + ' ' + options.y2 + ' z'
  let canvasObject = new fabric.Path(path, {
    ...options,
    stroke: options.color,
    strokeWidth: options.drawWidth,
    fill: options.fillColor
  })
  fabricCanvas.add(canvasObject)
  fabricCanvas.renderAll()
}

function createEqualTriangle(options) {
  options = Object.assign(
    {
      left: 100,
      top: 100,
      width: 50,
      height: 80,
      fillColor: 'rgba(255, 255, 255, 0)',
      color: '#B2B2B2',
      drawWidth: 2
    },
    options
  )
  let triangle = new fabric.Triangle({
    ...options,
    fill: options.fillColor,
    strokeWidth: options.drawWidth,
    stroke: options.color
  })
  setContronVisibility(triangle)
  fabricCanvas.add(triangle)
  fabricCanvas.renderAll()
}

function createLine(options) {
  options = Object.assign(
    {
      x: 0,
      y: 0,
      x1: 10,
      y1: 10,
      fillColor: 'rgba(255, 255, 255, 0)',
      strokeColor: '#B0B0B0'
    },
    options
  )
  const line = new fabric.Line([options.x, options.y, options.x1, options.y1], {
    ...options,
    fill: options.fillColor,
    stroke: options.strokeColor
  })
  fabricCanvas.add(line)
  fabricCanvas.renderAll()
}

function createEllipse(options) {
  options = Object.assign(
    {
      rx: 100,
      ry: 200,
      fillColor: 'rgba(255, 255, 255, 0)',
      angle: 90,
      strokeColor: '#B0B0B0',
      strokeWidth: 3,
      left: 50,
      top: 50
    },
    options
  )
  options.lockRotation = true
  const ellipse = new fabric.Ellipse({
    ...options,
    fill: options.fillColor,
    stroke: options.strokeColor
  })
  fabricCanvas.add(ellipse)
  fabricCanvas.renderAll()
}

/**
 * @param {string} text
 * @param {Partial<Fabric.FabricObject>} options
 * @return {Fabric.FabricObject}
 */
function createText(text, options) {
  options = Object.assign({ left: 100, top: 100 }, options)
  options.lockRotation = true
  options.lockScalingFlip = true
  options.lockScalingX = true
  options.lockScalingY = true
  const canvasObj = new fabric.Text(text, { ...options })
  fabricCanvas.add(canvasObj)
  fabricCanvas.renderAll()
  return canvasObj
}

function createItext(text, options) {
  options = Object.assign({ left: 0, top: 0, fill: '#000' }, options)
  options.lockRotation = true
  const IText = new fabric.IText(text, options)
  fabricCanvas.add(IText)
  fabricCanvas.renderAll()
}

function createTextBox(text, options) {
  // _fontSizeMult: 5,
  options.fillColor = options.fillColor ? options.fillColor : options.fill
  options = Object.assign(
    {
      fontSize: 14,
      fillColor: '#000000',
      registeObjectEvent: false,
      width: 50,
      left: 100,
      top: 100
    },
    options
  )
  const canvasObj = new fabric.Textbox(text, {
    ...options,
    fill: options.fillColor,
    lockRotation: true
  })
  // let arr = canvasObj._splitTextIntoLines(text);
  fabricCanvas.add(canvasObj)
  if (options.registeObjectEvent) {
    registerObjectEvent(canvasObj)
  }
  fabricCanvas.renderAll()
}

function createImageByImg(img, options) {
  options = options || {}
  let canvas = fabricCanvas
  let maxWidth = props.width
  let width = 0
  let height = 0
  width = img.width
  height = img.height
  if (options && options.width) {
    width = options.width
  }
  if (options && options.height) {
    height = options.height
  }
  let leftP = props.width / 2
  let topP = props.height / 2
  if ((options && options.left) || (options && Number(options.left) === 0)) {
    leftP = options.left + width / 2
  }
  if ((options && options.top) || (options && Number(options.top) == 0)) {
    topP = options.top + height / 2
  }
  let imgOptions = Object.assign(options, {
    id: options && options.id ? options.id : 'image',
    left: leftP,
    top: topP,
    scaleX: width / img.width,
    scaleY: height / img.height,
    originX: 'center',
    originY: 'center',
    cornerStrokeColor: 'blue'
  })
  delete imgOptions.width
  delete imgOptions.height
  const canvasImage = new fabric.Image(img, imgOptions)
  canvasImage.hasControls = true
  canvasImage.hasBorders = true
  canvas.add(canvasImage) // 把图片添加到画布上
  if (options && options.registeObjectEvent) {
    registerObjectEvent(canvasImage)
  }
  canvas.renderAll.bind(canvas)
}

function createImage(url, options) {
  return new Promise((resolve) => {
    options = options || {}
    let canvas = fabricCanvas
    fabric.Image.fromURL(url, function(img) {
      let width
      let height
      width = img.width
      height = img.height
      if (options && options.width) {
        width = options.width
      }
      if (options && options.height) {
        height = options.height
      }
      let leftP = props.width / 2
      let topP = props.height / 2
      if (options?.left || options.left === 0) {
        leftP = options.left + width / 2
      }
      if (options?.top || options.top === 0) {
        topP = options.top + height / 2
      }
      let imgOptions = Object.assign(options, {
        id: options && options.id ? options.id : 'image',
        left: leftP,
        top: topP,
        scaleX: width / img.width,
        scaleY: height / img.height,
        originX: 'center',
        originY: 'center',
        lockRotation: true,
        lockScalingFlip: true,
        lockScalingX: true,
        lockScalingY: true,
        cornerStrokeColor: 'blue'
      })
      delete imgOptions.width
      delete imgOptions.height
      img.set(imgOptions)
      const oldOriginX = img.get('originX')
      const oldOriginY = img.get('originY')
      const center = img.getCenterPoint()
      img.hasControls = true
      img.hasBorders = true
      // img.customiseCornerIcons(
      //   {
      //     settings: {
      //       borderColor: '#b4b4b4',
      //       cornerSize: 20,
      //       cornerBackgroundColor: '#FF0000',
      //       cornerShape: 'circle',
      //       cornerPadding: 0
      //     }
      //     tl: {
      //       icon: dotCircleImg
      //     },
      //     tr: {
      //       icon: dotCircleImg
      //     },
      //     bl: {
      //       icon: dotCircleImg
      //     },
      //     br: {
      //       icon: dotCircleImg
      //     },
      //     mb: {
      //       icon: dotCircleImg
      //     },
      //     mt: {
      //       icon: dotCircleImg
      //     },
      //     mr: {
      //       icon: dotCircleImg
      //     },
      //     mtr: {
      //       icon: dotCircleImg
      //     }
      //   },
      //   function () {
      //     canvas.renderAll();
      //   }
      // );
      // img.setControlsVisibility({
      //   bl: true,
      //   br: true,
      //   mb: false,
      //   ml: true,
      //   mr: true,
      //   mt: false,
      //   mtr: true,
      //   tl: true,
      //   tr: true
      // });
      canvas.add(img) // 把图片添加到画布上
      if (options && options.registeObjectEvent) {
        registerObjectEvent(img)
      }
      canvas.renderAll.bind(canvas)
      resolve(img)
    })
  })
}

function toJson() {
  return fabricCanvas.toJSON()
}

function toDataUrl() {
  let canvas = fabricCanvas
  let dataURL = canvas.toDataURL({
    format: 'jpeg',
    quality: 1
  })
  return dataURL
}

function loadFromJSON(json, cb) {
  const canvas = fabricCanvas
  canvas.loadFromJSON(json, canvas.renderAll.bind(canvas), (o, object) => {
    object.setControlsVisibility({
      bl: true,
      br: true,
      mb: true,
      ml: true,
      mr: true,
      mt: true,
      mtr: true,
      tl: true,
      tr: true
    })
    cb(o)
  })
}

function clear() {
  fabricCanvas.clear()
}

function getObjects() {
  return fabricCanvas.getObjects()
}

/**
 * @return {Fabric.FabricObject}
 */
function getActiveObject() {
  return fabricCanvas.getActiveObject()
}

function renderAll() {
  fabricCanvas.renderAll(fabricCanvas)
}

function renderTop() {
  fabricCanvas.renderTop()
}

function setBackgroundColor(color) {
  let canvas = fabricCanvas
  fabricCanvas.setBackgroundColor(color, canvas.renderAll.bind(canvas))
}

function setBackgroundImage(options) {
  let canvas = fabricCanvas
  let opt = {
    opacity: 1,
    left: 0,
    top: 0,
    angle: 0,
    repeat: 'repeat',
    originX: 'left',
    originY: 'top',
    scaleX: 1,
    scaleY: 1
  }
  if (Object.prototype.toString.call(options) === '[object String]') {
    console.log('字符串兼容')
    opt.imgUrl = options
  } else {
    opt = Object.assign(opt, options)
  }
  fabric.Image.fromURL(opt.imgUrl, function(img) {
    // opt.scaleX = canvas.width / img.width,
    // opt.scaleY = canvas.height / img.height,
    img.set({
      width: opt.width ? opt.width : canvas.width,
      height: opt.height ? opt.height : canvas.height,
      originX: 'left',
      originY: 'top',
      scaleX: canvas.width / img.width,
      scaleY: canvas.height / img.height
    })
    canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), { ...opt })
  })
}

function toSvg() {
  return fabricCanvas.toSVG()
}

function drawControls() {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  ctx.setLineDash([])
  ctx.beginPath()
  ctx.ellipse(100, 100, 50, 75, (45 * Math.PI) / 180, 0, 2 * Math.PI) // 倾斜45°角
  ctx.stroke()
  ctx.setLineDash([5])
  ctx.moveTo(0, 200)
  ctx.lineTo(200, 0)
  ctx.stroke()
  fabricCanvas.drawControls(ctx)
  // fabricCanvas.controlsAboveOverlay=true;
}

function setContronVisibility(obj) {
  obj.setControlsVisibility({
    bl: true,
    br: true,
    mb: true,
    ml: true,
    mr: true,
    mt: true,
    mtr: true,
    tl: true,
    tr: true
  })
}

function toggleMirror(options) {
  options = options || {}
  options = Object.assign({ flip: 'X' }, options)
  let img = fabricCanvas.getActiveObject()
  // if (img && img.type == 'image') {
  if (options.flip === 'X') {
    img.toggle('flipX')
  } else {
    img.toggle('flipY')
  }
  renderAll()
  // }
}

function toNextLayer() {
  let obj = fabricCanvas.getActiveObject()
  if (!obj) {
    return
  }
  obj.sendBackwards(true)
  renderTop()
  // fabricCanvas.setActiveObject(obj);
}

function toBottomLayer() {
  let obj = fabricCanvas.getActiveObject()
  if (!obj) {
    return
  }
  obj.sendToBack()
  renderTop()
  // fabricCanvas.setActiveObject(obj);
}

function toLastLayer() {
  let obj = fabricCanvas.getActiveObject()
  if (!obj) {
    return
  }
  obj.bringForward(true)
  renderTop()
}

function toTopLayer() {
  let obj = fabricCanvas.getActiveObject()
  if (!obj) {
    return
  }
  obj.bringToFront()
  renderTop()
}

function drawByPath(pathArray, options) {
  options = Object.assign(
    {
      fillColor: 'rgba(255, 255, 255, 0)',
      left: 150,
      top: 150,
      strokeColor: '#B0B0B0',
      strokeWidth: 3
    },
    options
  )
  let pathStr = 'M '
  for (let item of pathArray) {
    pathStr = pathStr + item[0] + ' ' + item[1] + ' '
  }
  pathStr = pathStr + 'z'
  const path = new fabric.Path(pathStr)
  path.set({
    ...options,
    stroke: options.strokeColor,
    fill: options.fillColor
  })
  fabricCanvas.add(path)
}

function handleFabricDelete() {
  const objs = fabricCanvas.getActiveObjects()
  objs.forEach((obj) => {
    fabricCanvas.remove(obj)
  })
  fabricCanvas.renderAll()
  $emit('deleted', objs)
}

function rotate() {
  setRotate()
}

function selected(obj, option) {
  setSelection(true)
}

function changeDrawMore() {
  reactiveData.isDrawingMode = !reactiveData.isDrawingMode
  freeDrawConfig({ isDrawingMode: reactiveData.isDrawingMode })
}

function changeDrawModel(change) {
  reactiveData.drawType = change
  switch (change) {
    case 'text':
      setSelection(false)
      break
    case 'select':
      setSelection(true)
      break
  }
}

function registerObjectEvent(obj) {
  obj.on('mousedown', function(options) {
    console.debug('object:mousedown')
    $emit('object:mousedown', obj, options)
  })
  obj.on('mouseup', function(options) {
    console.debug('object:mouseup')
    $emit('object:mouseup', obj, options)
  })
  obj.on('mousemove', function(options) {
    console.debug('object:mousemove')
    $emit('object:mousemove', obj, options)
  })
  obj.on('mouseover', function(options) {
    console.debug('object:mouseover')
    $emit('object:mouseover', obj, options)
  })
  obj.on('mouseout', function(options) {
    console.debug('object:mouseout')
    $emit('object:mouseout', obj, options)
  })
  obj.on('mousedblclick', function(options) {
    console.debug('object:mousedblclick')
    $emit('object:mousedblclick', obj, options)
  })
  obj.on('mousewheel', function(options) {
    console.debug('object:mousewheel')
    $emit('object:mousewheel', obj, options)
  })
}

defineExpose({
  getActiveObject,
  getObjects,
  getEditObj,
  addObj,
  removeCurrentObj,
  removeByObj(obj) {
    if (!obj) return
    return fabricCanvas.remove(obj)
  },
  createText,
  loadFromJSON,
  createImage,
  toJson
})

const {
  aka,
  canvas,
  currentObj,
  isDrawingMode,
  drawType,
  doDrawing,
  textBox,
  moveCount,
  mouseFrom,
  mouseTo,
  active
} = toRefs(reactiveData)
</script>
<script>
export default {
  name: 'VueFabric'
}
</script>
<style lang="scss" scoped>
@import 'index';
</style>
