import { listAllDictionary } from '@/api/dictionary'

const platformCode = ['operationSequence', 'unit', 'bigClassCode', 'classCode', 'storageMode']
const state = {
	list: [],
	systemDictionary: {}
}
const mutations = {
	SET_LIST(state, list) {
		state.list = list
	},
	SET_SYSTEM_DICTIONARY(state, systemDictionary) {
		state.systemDictionary = systemDictionary
	}
}
const actions = {
	GetDictionary({ state }) {
		return state.list
	},
	SetDictionary({ commit, dispatch }) {
		return new Promise((resolve, reject) => {
			listAllDictionary()
				.then((res) => {
					commit('SET_LIST', res.data)
					dispatch('SetSystemDictionary')
					resolve()
				})
				.catch(reject)
		})
	},
	SetSystemDictionary({ commit, state }) {
		const list = state.list
		const filter = list.filter((i) => i.tenantId === '0' && platformCode.includes(i.code))
		const system = {}
		filter.forEach((v, k) => {
			system[v.code] = v
		})
		commit('SET_SYSTEM_DICTIONARY', system)
	}
}
export default {
	namespaced: true,
	state,
	mutations,
	actions
}
