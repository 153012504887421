<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
export default {
	name: 'App',
	metaInfo() {
		return {
			title: this.$store.state.settings.dynamicTitle && this.$store.state.settings.title,
			titleTemplate: (title) => {
				return title ? `${title} - ${process.env.VUE_APP_TITLE}` : process.env.VUE_APP_TITLE
			}
		}
	},
	mounted() {}
}
</script>
<style lang="scss">
@import 'assets/styles/global.scss';
</style>
